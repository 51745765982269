import Image from "next/image";
import React from "react";

export default function Loading() {
  return (
    <div className="bg-amber-500 place-items-center grid h-full w-full gap-4 absolute -translate-x-1/2 -translate-y-1/2 top-2/4 left-1/2 z-50">
    <div className="bg-amber-300 w-48 h-48  absolute animate-ping rounded-full delay-5s shadow-xl" />
    <div className="bg-amber-100 w-32 h-32 absolute animate-ping rounded-full shadow-xl" />
    <div className="bg-zinc-800 bg-opacity-0  w-24 h-24 absolute animate-pulse rounded-full shadow-xl" />
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="60"
      height="40"
      viewBox="0 0 71 80"
      fill="none"
    >
      <g id="logo 1" clipPath="url(#clip0_1257_204)">
        <path
          id="Vector"
          d="M68.1638 67.4731H67.6826V67.2852H68.8719V67.4731H68.3887V68.7785H68.1638V67.4731Z"
          fill="white"
        />
        <path
          id="Vector_2"
          d="M70.3842 68.1548C70.3842 67.8809 70.3868 67.5993 70.3934 67.44H70.3868C70.3204 67.693 70.0949 68.3076 69.9266 68.7759H69.7281C69.5999 68.3895 69.3678 67.7001 69.2988 67.4373H69.2916C69.3034 67.611 69.3087 67.9369 69.3087 68.1925V68.7759H69.0996V67.2832H69.4342C69.5953 67.7333 69.7892 68.3095 69.8418 68.5066H69.8438C69.8793 68.3544 70.1114 67.7216 70.2797 67.2832H70.6044V68.7759H70.3868V68.1548H70.3842Z"
          fill="white"
        />
        <path
          id="Vector_3"
          d="M67.5926 71.54C67.517 68.889 65.4694 68.7504 63.4506 68.7153C61.6522 68.6821 58.5623 68.6821 56.7638 68.7153C54.745 68.7504 52.6978 68.889 52.622 71.54C52.622 71.54 52.5366 73.127 52.5366 74.5995C52.5366 75.8698 52.622 77.0561 52.622 77.0561C52.6978 79.7078 54.745 79.8457 56.7638 79.8808C58.5623 79.914 61.6522 79.914 63.4506 79.8808C65.4694 79.8457 67.517 79.7078 67.5926 77.0561C67.5926 77.0561 67.678 75.4691 67.678 73.9966C67.678 72.7238 67.5926 71.54 67.5926 71.54ZM64.2704 75.6779C64.2278 77.0067 63.0904 77.075 61.9673 77.0938C60.9673 77.1101 59.2471 77.1101 58.2471 77.0938C57.124 77.075 55.9843 77.0067 55.944 75.6779C55.944 75.6779 55.8966 75.0847 55.8966 74.4467C55.8966 73.7111 55.944 72.9137 55.944 72.9137C55.9843 71.5849 57.124 71.5166 58.2471 71.5003C59.2471 71.4841 60.9673 71.4841 61.9673 71.5003C63.0904 71.5192 64.2301 71.5869 64.2704 72.9137C64.2704 72.9137 64.3178 73.5069 64.3178 74.1449C64.3178 74.8831 64.2704 75.6779 64.2704 75.6779Z"
          fill="white"
        />
        <path
          id="Vector_4"
          d="M48.2361 75.5395L48.2313 76.0319C48.2313 76.6601 47.902 77.1525 46.8902 77.1525C46.8902 77.1525 44.9567 77.1876 44.3098 77.1876C42.3715 77.1876 41.9474 77.0914 41.8976 77.0842C41.419 77.0257 41.0991 76.6881 40.9783 76.2309C40.8479 75.7366 40.8788 75.0237 40.8788 74.4C40.8788 73.5024 40.893 73.0543 40.983 72.4358C41.0636 71.8823 41.5422 71.4556 42.1251 71.4556C42.1251 71.4556 43.3359 71.3853 44.5775 71.3853C45.876 71.3853 47.2077 71.4556 47.2077 71.4556C47.6674 71.4556 48.0417 71.8237 48.0417 72.2784V72.7193L51.4017 72.7239C51.3969 72.0442 51.3875 71.5375 51.3875 71.5375C51.3235 69.1187 49.5582 68.748 47.6721 68.7128C45.9945 68.6803 43.1013 68.6192 41.4285 68.7506C39.8148 68.8767 37.6751 68.9053 37.6041 71.5564C37.6041 71.5564 37.5093 72.9561 37.5093 74.426C37.5093 75.6962 37.5661 77.0517 37.5661 77.0517C37.8576 79.6162 39.5423 79.6891 41.4237 79.8153C43.08 79.9278 46.0158 79.9115 47.6602 79.8296C51.8756 79.6162 51.5533 78.2426 51.5936 75.5323L48.2361 75.5395Z"
          fill="white"
        />
        <path
          id="Vector_5"
          d="M36.5737 68.8444V79.7478H33.3038V71.5611H32.7731L28.6383 79.7478H25.7949L21.66 71.5611H21.1293V79.7478H17.8594V68.8418H23.9443L27.2166 75.9924L30.4912 68.8444H36.5737Z"
          fill="white"
        />
        <path
          id="Vector_6"
          d="M11.1296 68.8398H5.88111L0 79.7478H3.90969L4.90014 77.9072H12.0323L13.0086 79.7478H16.9182L11.1296 68.8398ZM6.1773 75.5397L8.40936 71.3998H8.57759L10.7741 75.5397H6.1773Z"
          fill="white"
        />
        <path
          id="Vector_7"
          d="M67.6547 37.105H59.0533C57.1483 37.105 55.5559 35.6914 55.3498 33.8183L54.9778 30.4074H50.1914L49.8194 33.816C49.6132 35.6914 48.0209 37.105 46.1158 37.105H40.2205C38.3154 37.105 36.7231 35.6914 36.517 33.8183L36.145 30.4074H31.3586L30.9866 33.816C30.7804 35.6914 29.1881 37.105 27.283 37.105H21.3925C19.4826 37.105 17.8903 35.6891 17.6889 33.809L17.3264 30.4074H12.5471L12.1845 33.809C11.9831 35.6891 10.3908 37.1074 8.4786 37.1074H0.050293V32.8103H7.92415L8.28671 29.4088C8.48814 27.5286 10.0804 26.1104 11.9902 26.1104H17.8785C19.7882 26.1104 21.3806 27.5263 21.582 29.4064L21.9469 32.808H26.7286L27.1006 29.3994C27.3067 27.5239 28.8991 26.1104 30.8041 26.1104H36.6995C38.6045 26.1104 40.1968 27.5239 40.403 29.397L40.775 32.808H45.5614L45.9334 29.3994C46.1396 27.5239 47.7319 26.1104 49.6369 26.1104H55.5323C57.4374 26.1104 59.0297 27.5239 59.2358 29.397L59.6078 32.808H67.6547V37.105Z"
          fill="white"
        />
        <path
          id="Vector_8"
          d="M42.0944 0.965841C41.8219 5.13396 38.3174 8.42062 34.0286 8.42062C29.7943 8.42062 26.3111 5.1996 25.9746 1.07368C28.6119 0.365707 31.3677 0 34.2229 0C36.9383 0 39.5756 0.332887 42.0944 0.965841Z"
          fill="white"
        />
        <path
          id="Vector_9"
          d="M60.3417 13.3599C54.2521 16.6793 44.9802 19.0635 33.8436 19.0635C23.0433 19.0635 13.9895 16.8177 7.89746 13.66C6.66061 15.4111 5.60617 17.2912 4.76025 19.2768C12.1105 23.1425 22.3467 25.5056 33.8436 25.5056C45.6555 25.5056 56.1525 23.0042 63.5335 18.9439C62.6662 16.9701 61.5905 15.1017 60.3417 13.3599Z"
          fill="white"
        />
        <path
          id="Vector_10"
          d="M48.5748 3.36182C45.0893 7.6143 39.7816 10.3431 33.8436 10.3431C28.0359 10.3431 22.8254 7.73391 19.3399 3.64078C17.3969 4.63944 15.5842 5.83268 13.9019 7.21814C18.5698 13.0507 25.7778 16.7874 33.8436 16.7874C42.0823 16.7874 49.4207 12.8772 54.0792 6.84305C52.3755 5.49979 50.5392 4.32766 48.5748 3.36182Z"
          fill="white"
        />
        <path
          id="Vector_11"
          d="M42.0944 62.1935C41.8219 58.0254 38.3174 54.7388 34.0286 54.7388C29.7943 54.7388 26.3111 57.9598 25.9746 62.0857C28.6119 62.7937 31.37 63.1594 34.2253 63.1594C36.9383 63.1594 39.5756 62.8265 42.0944 62.1935Z"
          fill="white"
        />
        <path
          id="Vector_12"
          d="M60.3417 49.7994C54.2521 46.4799 44.9802 44.0958 33.8436 44.0958C23.0433 44.0958 13.9895 46.3392 7.89746 49.497C6.66061 47.7458 5.60617 45.8657 4.76025 43.8801C12.1105 40.0144 22.3467 37.6514 33.8436 37.6514C45.6555 37.6514 56.1525 40.155 63.5335 44.213C62.6662 46.1892 61.5905 48.0599 60.3417 49.7994Z"
          fill="white"
        />
        <path
          id="Vector_13"
          d="M48.5748 59.7978C45.0893 55.5452 39.7816 52.8165 33.8436 52.8165C28.0359 52.8165 22.8254 55.4257 19.3399 59.5187C17.3969 58.5201 15.5842 57.3269 13.9019 55.9414C18.5698 50.1089 25.7778 46.3721 33.8436 46.3721C42.0823 46.3721 49.4207 50.2823 54.0792 56.3188C52.3755 57.6597 50.5392 58.8319 48.5748 59.7978Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_1257_204">
          <rect width="71" height="80" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </div>
  );
}
