// /components/NextBreadcrumb.jsx
"use client"
import React from 'react';
import { usePathname } from 'next/navigation';
import Link from 'next/link';

const NextBreadcrumb = ({
    homeElement,
    separator,
    containerClasses = '',
    listClasses = '',
    activeClasses = '',
    capitalizeLinks = true,
}) => {
    const paths = usePathname();
    const pathNames = paths.split('/').filter(path => path);

    return (
        paths === "/" ? null : (
            <nav aria-label="breadcrumbs" className='w-full relative z-30 -mb-16'>
                <ul className={containerClasses}>
                    <li className={`text-white ${listClasses}`}>
                        <Link href="/"> {homeElement} </Link>
                    </li>
                    {pathNames.length > 0 && separator}
                    {pathNames.map((link, index) => {
                        const href = `/${pathNames.slice(0, index + 1).join('/')}`;
                        const itemClasses = paths === href ? `${listClasses} ${activeClasses}` : `text-white ${listClasses}`;
                        const itemLink = capitalizeLinks ? link[0].toUpperCase() + link.slice(1) : link;
                        const itemLinkSenitize = itemLink.replace(/-+/g, ' ');

                        return (
                            <React.Fragment key={index}>
                                <li className={itemClasses}>
                                    <Link href={href}>{itemLinkSenitize}</Link>
                                </li>
                                {pathNames.length !== index + 1 && separator}
                            </React.Fragment>
                        );
                    })}
                </ul>
            </nav>
        )
    );
};

export default NextBreadcrumb;
